import React from 'react';
import './loader.css';
import { Spinner } from 'react-bootstrap';

function Loader() {
  return (
    <div className="loader-global">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default Loader;
